.nav-tabs {
  margin-bottom: -5px!important;
  margin-left: 0.3rem;
}

.nav-item {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 5px 0px 3px rgba(0, 0, 0, 0.08);

  float: left;
  position: relative;
  margin-left: 0.2rem;
  margin-bottom: 0.08rem;
}

a.nav-link {
  background: #bebebe!important;
  color: #3b3f5c!important;
}

a.nav-link:before, a.nav-link:after {
  content: '';
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: -1px;
}

a.nav-link:after {
  right: -20px;
  border-radius: 0 0 0 8px;
  box-shadow: -10px 0 0 0 #bebebe;
  transition: 0.2s;
}

a.nav-link:before {
  left: -20px;
  border-radius: 0 0 8px 0;
  box-shadow: 10px 0 0 0 #bebebe;
  transition: 0.2s;
}

a.nav-link.active {
  background: #f6f8fa!important;
  color: #8c8c8c!important;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
}

a.nav-link.active:after {
  box-shadow: -10px 0 0 0 #f6f8fa;
  transition: 0.2s;
  z-index: 1;
}

a.nav-link.active:before {
  box-shadow: 10px 0 0 0 #f6f8fa;
  transition: 0.2s;
  z-index: 1;
}
