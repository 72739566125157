label {
  font-weight: 500;
  color: #6f6f6f;
}

.button-pay {
  padding-top: 10px;
  height: 100%;
}

.help-block {
  color: red;
}

.input-cartao-pagamento {
  border-radius: 10px!important;
  border: 1px solid #8a8a8a!important;
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

select.form-select.input-cartao-pagamento {
  background-image: none;
  display: initial;
  line-height: 1.6;
  border-radius: 3px;
  border-color: #ced4da;
  border-width: 1px;
  width: 100%;
  max-height: 57px;
  height: 100%;
}

.alinhamento-label-cartao {
  max-width: 510px;
  margin: 0 auto;
  margin-top: 5px;
}
.colunas-form-pagamento-cartao {
  padding: 5px 10px;
}

.cvv-form-cartao {
  font-size: 36px;
  line-height: 0;
  padding: 5px 10px;
}
.form-select {
  display: initial;
  padding: 16px;
  line-height: 1.6;
}
.rodape-btn-pagamento {
  -webkit-box-shadow: 0px -3px 12px 0px rgb(0 0 0 / 7%);
  box-shadow: 0px -3px 12px 0px rgb(0 0 0 / 7%);
  padding: 15px 0px 0px 0px;
  text-align: center;
  margin-top: 25px;
}
.btn-primary {
  border-width: 5px;
  padding: 10px 20px;
  /* margin: 0px 20px; */
  border-radius: 10px;
}
.btn-outline-primary {
  border-width: 1px;
  border-radius: 10px;
  padding: 14px 20px;
  margin: 0px 20px;
}
@media (max-width: 1024px) {
  .form-select {
    background-image: none;
  }
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
